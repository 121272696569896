import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"
import Date from "../components/date"
import ReactMarkdown from 'react-markdown'
import AniLink from "gatsby-plugin-transition-link/AniLink"


export default ({ data }) => {
  return (
    <div className="note">
      <Layout>
        <HelmetDatoCms seo={data.datoCmsBlogPost.seoMetaTags} />
        <div className="section-container s-note">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 note-container">
                <AniLink cover to="/notes" className="btn btn-blog" bg="#060606" duration={1} direction="top">
                    Back to notes
                </AniLink>
                {/* <Link to="/notes" className="btn btn-blog">Back to notes</Link> */}
                <div className="c-mb-3"></div>
                <h1>{data.datoCmsBlogPost.title}</h1>
                <div className="date"><Date toParse={data.datoCmsBlogPost.date} /> - ({data.datoCmsBlogPost.readingTime} min read)</div>
                <ReactMarkdown>{data.datoCmsBlogPost.postBody}</ReactMarkdown>
              </div>
            </div>
          </div>
          <div
          />
        </div>

        <div className="section section-container s-footer-notes">
          <Link to="https://twitter.com/andrea_rugge" target="_blank" rel="noopener">@andrea_rugge</Link>
          <div>@2021 Andrea Ruggeri. All rights reserved.</div>
        </div>

      </Layout>
    </div>
  )
}

export const query = graphql`
  query NoteQuery($slug: String!) {
    datoCmsBlogPost(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      readingTime
      date(formatString: "DD-MM-YYYY")
      excerpt
      postBody
      }
    }
`
